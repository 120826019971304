<template>
  <div>
    <Header />

    <section class="guide-dem-header">
      <h1>
        <router-link :to="{ name: 'guide-dem-list' }" rel="canonical">
          Location-gardemeuble.fr : Guide Déménagement
        </router-link>
      </h1>

      <div class="guide-center">
        <h2>
          Chaque saison est une occasion de déménager
        </h2>
        <p>
          Alors que l'été est la période où l'on déménage le plus, les Français se déplacent toute l'année.<br />
          Voici nos meilleurs conseils et astuces pour que votre prochain déménagement soit le plus simple possible.
        </p>
      </div>

      <nav v-if="this.store.getters.isDesktop">
        <router-link v-for="cat in this.categories" :to="{ name: 'guide-dem-category', params: { slug: cat.slug, category: cat.id } }">
          {{ cat.name }}
        </router-link>
      </nav>

      <div class="cat-selector" v-if="this.store.getters.isMobile">
        <label for="cat">Sélectionnez une catégorie :</label>
        <select id="cat" @change="this.onCategoryChange($event)">
          <option value="guide-demenagement-sommaire">
            Sommaire du guide
          </option>
          <option v-for="cat in this.categories" :value="cat.id">
            {{ cat.name }}
          </option>
        </select>
        <br><br>
      </div>
    </section>

    <div class="articles-container">
      <section v-for="cat in this.categories" class="articles">
        <div class="title">
          {{ cat.sub }}
        </div>
        <div :class="(cat.id % 2 ? 'row' : 'col') + ' list'">
          <article v-for="article in this.home_articles.filter( art => art.category_id === cat.id ).splice( 0, cat.id % 2 ? 4 : 6 )">
            <a :href="article.url">
              <p class="title">
                {{ article.title }}
              </p>
              <p class="text" v-if="cat.id % 2 === 0">
                {{ article.extract }}
              </p>
            </a>
            <a :href="article.url" class="img">
              <img :src="this.store.state.api + '/img/upload/dem_articles/' + article.id + '.jpg'" :alt="article.title" width="250" height="150" />
            </a>
          </article>
        </div>
      </section>
    </div>

    <Footer />
  </div>
</template>

<script>
import { Options, setup, Vue } from "vue-class-component";
import { computed, defineAsyncComponent } from "vue";
import { useStore } from "vuex";
import { key } from "@/store";
import { useMeta } from "vue-meta";

@Options( {
  components : {
    Header : defineAsyncComponent( () => import("@/components/Header.vue") ),
    Footer : defineAsyncComponent( () => import("@/components/Footer.vue") )
  }
} )
export default class extends Vue {
  store = useStore( key );

  categories    = [];
  home_articles = [];

  meta = setup( () => useMeta( computed( () => {
    return {
      title       : [{
        content : "Guide du déménagement - Sommaire",
        vmid    : "title",
        name    : "title"
      }],
      description : {
        content : "Le guide du déménagement de Location-gardemeuble.fr, tout savoir sur comment bien déménager sans s'embêter !",
        vmid    : "description",
        name    : "description"
      }
    };
  } ) ) );

  onCategoryChange( event )
  {
    // Find cateogory by id from this.categories
    let category = this.categories.find( cat => cat.id === parseInt( event.target.value ) );

    if( event.target.value === "guide-demenagement-sommaire" )
    {
      // Redirect to guide
      this.$router.push( { name : "guide-dem-list" } );
      return;
    }

    // Redirect to category
    this.$router.push( { name : "guide-dem-category", params : { slug : category.slug, category : category.id } } );
  }

  async getCategories()
  {
    let request = await fetch( this.store.state.api + "guide/demenagement/categories", {
      method  : "GET",
      cache   : "no-cache",
      headers : {
        "Content-Type" : "application/json",
        "Accept"       : "application/json"
      }
    } );

    let json        = await request.json();
    this.categories = json.data;
  }

  async getHomeArticles()
  {
    let request = await fetch( this.store.state.api + "guide/demenagement/articles/home", {
      method  : "GET",
      cache   : "no-cache",
      headers : {
        "Content-Type" : "application/json",
        "Accept"       : "application/json"
      }
    } );

    let json           = await request.json();
    this.home_articles = json.data;
  }

  async mounted()
  {
    try
    {
      await this.getCategories();
      await this.getHomeArticles();
    }
    catch( e )
    {
      console.error( e );
      // TODO : Better error handling
    }

    setTimeout( () => {
      this.store.state.loading = false;

      // Remove div#preload from DOM
      let preload = document.getElementById( "preload" );
      if( preload )
      {
        window.scrollTo( { top : 0 } );
        preload.remove();
      }
    }, this.store.state.delay );
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/global.scss";

@media (max-width : 768px)
{

  .articles
  {
    .title
    {
      text-align : center !important;
      margin-top : 2.5em !important;
    }

    .list.col article
    {
      flex-direction : column !important;
      margin         : 0 1em !important;

      .text
      {
        display : none;
      }

      .img
      {
        width : 100%;
        order : 1 !important;

        img
        {
          width  : 100%;
          height : auto !important;
        }
      }
    }

    .list.row
    {
      flex-direction : column !important;

      article > a
      {
        font-weight : bold;
      }

      .img
      {
        width : 100%;

        img
        {
          width  : 100%;
          height : auto !important;
        }
      }
    }
  }
}
</style>
